/* eslint-disable react/destructuring-assignment */
import React from 'react'
import Head from 'next/head'
import { appWithTranslation } from 'next-i18next'
import '../src/styles/index.scss'
import LoadingIndicator from '../src/components/common/Layout/LoadingIndicator'
// import '../src/lib/antd/antd.css'

const MyApp = (props) => {
  const { Component, pageProps } = props
  return (
    <>
      <Head>
        <title>Sim Travel</title>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
        <title>Sim Travel</title>
        <meta name="description" content="Chuyên trang cung cấp sim du lịch Việt Nam - Quốc tế và Sim Quốc tế - Việt Nam, cung cấp thông tin hữu ích cho khách hàng muốn khám phá những cùng đất mới tại Việt Nam và Quốc tế." />
        <meta itemProp="name" content="Sim Travel - Luôn bên bạn trên hành trình khám phá thế giới" />
        <meta itemProp="description" content="Chuyên trang cung cấp sim du lịch Việt Nam - Quốc tế và Sim Quốc tế - Việt Nam, cung cấp thông tin hữu ích cho khách hàng muốn khám phá những cùng đất mới tại Việt Nam và Quốc tế." />
        <meta name="twitter:title" content="Sim Travel - Luôn bên bạn trên hành trình khám phá thế giới" />
        <meta name="twitter:description" content="Chuyên trang cung cấp sim du lịch Việt Nam - Quốc tế và Sim Quốc tế - Việt Nam, cung cấp thông tin hữu ích cho khách hàng muốn khám phá những cùng đất mới tại Việt Nam và Quốc tế." />
        <meta name="twitter:card" content="summary_large_image" />

        <meta property="og:title" content="Sim Travel - Luôn bên bạn trên hành trình khám phá thế giới" />

        <meta property="og:description" content="Chuyên trang cung cấp sim du lịch Việt Nam - Quốc tế và Sim Quốc tế - Việt Nam, cung cấp thông tin hữu ích cho khách hàng muốn khám phá những cùng đất mới tại Việt Nam và Quốc tế." />

        <meta
          property="og:image"
          content="https://vietnamtouristsimcard.vn/images/common/simtravel-favicon.png"
        />

        <meta property="og:image:width" content="600" />
        <meta property="og:image:height" content="600" />
        <meta property="og:url" content="https://vietnamtouristsimcard.vn" />

        <link
          async
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.0/dist/css/bootstrap.min.css"
        />
        <link rel="shortcut icon" href="/images/common/simtravel-favicon.png" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin=""
        />
      </Head>
      <LoadingIndicator />
      <Component {...pageProps} />
    </>
  )
}

export default appWithTranslation(MyApp)
